import * as React from "react";
const DownloadIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    // fill="red"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 2a1 1 0 0 1 1 1v10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L11 13.586V3a1 1 0 0 1 1-1zM5 17a1 1 0 0 1 1 1v2h12v-2a1 1 0 1 1 2 0v2a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2a1 1 0 0 1 1-1z" />
  </svg>
);
export default DownloadIcon;
