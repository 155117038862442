import * as React from "react";
const DesignBackgroundImage1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={106}
    height={107}
    viewBox="0 0 106 107"
    fill="none"
    {...props}
  >
    <mask id="path-1-inside-1_200_1457" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 27.7058H26.1087H52.2175V53.8145H26.1087V79.9233H52.2175V106.032H26.1087H0V79.9233V53.8145V27.7058Z"
      />
    </mask>
    <path
      d="M0 27.7058V26.7058H-1V27.7058H0ZM52.2175 27.7058H53.2175V26.7058H52.2175V27.7058ZM52.2175 53.8145V54.8145H53.2175V53.8145H52.2175ZM26.1087 53.8145V52.8145H25.1087V53.8145H26.1087ZM26.1087 79.9233H25.1087V80.9233H26.1087V79.9233ZM52.2175 79.9233H53.2175V78.9233H52.2175V79.9233ZM52.2175 106.032V107.032H53.2175V106.032H52.2175ZM0 106.032H-1V107.032H0V106.032ZM26.1087 26.7058H0V28.7058H26.1087V26.7058ZM52.2175 26.7058H26.1087V28.7058H52.2175V26.7058ZM53.2175 53.8145V27.7058H51.2175V53.8145H53.2175ZM26.1087 54.8145H52.2175V52.8145H26.1087V54.8145ZM27.1087 79.9233V53.8145H25.1087V79.9233H27.1087ZM26.1087 80.9233H52.2175V78.9233H26.1087V80.9233ZM51.2175 79.9233V106.032H53.2175V79.9233H51.2175ZM52.2175 105.032H26.1087V107.032H52.2175V105.032ZM0 107.032H26.1087V105.032H0V107.032ZM-1 79.9233V106.032H1V79.9233H-1ZM-1 53.8145V79.9233H1V53.8145H-1ZM-1 27.7058V53.8145H1V27.7058H-1Z"
      fill="#3b82f6"
      mask="url(#path-1-inside-1_200_1457)"
    />
    <mask
      id="path-3-outside-2_200_1457"
      maskUnits="userSpaceOnUse"
      x={51.2175}
      y={0.597046}
      width={55}
      height={81}
      fill="black"
    >
      <rect fill="white" x={51.2175} y={0.597046} width={55} height={81} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2175 1.59705H78.3262H104.435V27.7058V53.8145V79.9233H78.3262H52.2175V53.8145H78.3262V27.7058H52.2175V1.59705Z"
      />
    </mask>
    <path
      d="M52.2175 1.59705V0.597046H51.2175V1.59705H52.2175ZM104.435 1.59705H105.435V0.597046H104.435V1.59705ZM104.435 79.9233V80.9233H105.435V79.9233H104.435ZM52.2175 79.9233H51.2175V80.9233H52.2175V79.9233ZM52.2175 53.8145V52.8145H51.2175V53.8145H52.2175ZM78.3262 53.8145V54.8145H79.3262V53.8145H78.3262ZM78.3262 27.7058H79.3262V26.7058H78.3262V27.7058ZM52.2175 27.7058H51.2175V28.7058H52.2175V27.7058ZM78.3262 0.597046H52.2175V2.59705H78.3262V0.597046ZM104.435 0.597046H78.3262V2.59705H104.435V0.597046ZM105.435 27.7058V1.59705H103.435V27.7058H105.435ZM105.435 53.8145V27.7058H103.435V53.8145H105.435ZM105.435 79.9233V53.8145H103.435V79.9233H105.435ZM78.3262 80.9233H104.435V78.9233H78.3262V80.9233ZM78.3262 78.9233H52.2175V80.9233H78.3262V78.9233ZM53.2175 79.9233V53.8145H51.2175V79.9233H53.2175ZM52.2175 54.8145H78.3262V52.8145H52.2175V54.8145ZM77.3262 27.7058V53.8145H79.3262V27.7058H77.3262ZM52.2175 28.7058H78.3262V26.7058H52.2175V28.7058ZM51.2175 1.59705V27.7058H53.2175V1.59705H51.2175Z"
      fill="#3b82f6"
      mask="url(#path-3-outside-2_200_1457)"
    />
  </svg>
);
export default DesignBackgroundImage1;
