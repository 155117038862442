import * as React from "react";
const HamburgerIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={5} width={24} height={2} fill="#D9D9D9" />
    <rect x={9} y={12} width={15} height={2} fill="#D9D9D9" />
  </svg>
);
export default HamburgerIcon;
