import * as React from "react";
const EmailReplyLogo = (props) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 32 32"
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{"\n      .cls-1 {\n        fill: none;\n      }\n    "}</style>
    </defs>
    <title>{"mail--reply"}</title>
    <path d="M25,18l-1.4141,1.4141L26.1672,22H18a4,4,0,0,0,0,8h2V28H18a2,2,0,0,1,0-4h8.1672l-2.5827,2.5874L25,28l5-5Z" />
    <path d="M10,22H4L3.9966,6.9064l11.4341,7.9159a1.0008,1.0008,0,0,0,1.1386,0L28,6.9086,28,16h2V6a2.0025,2.0025,0,0,0-2-2H4A2,2,0,0,0,2,5.9965V22a2.0026,2.0026,0,0,0,2,2h6ZM25.7986,6,16,12.7837,6.2014,6Z" />
    <rect
      id="_Transparent_Rectangle_"
      data-name="&lt;Transparent Rectangle&gt;"
      className="cls-1"
      width={32}
      height={32}
    />
  </svg>
);
export default EmailReplyLogo;
