const CloseIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width={1}
      height={1}
      transform="matrix(1 0 0 -1 11 12)"
      fill="#D9D9D9"
    />
    <rect
      x={3}
      y={19.9706}
      width={24}
      height={2}
      transform="rotate(-45 3 19.9706)"
      fill="#D9D9D9"
    />
    <rect
      x={4}
      y={3}
      width={24}
      height={2}
      transform="rotate(45 4 3)"
      fill="#D9D9D9"
    />
  </svg>
);
export default CloseIcon;
