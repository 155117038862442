import * as React from "react";
const OfflineLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g id="Background_Complete">
      <g>
        <g>
          <path
            style={{
              fill: "#F5F5F5",
            }}
            d="M195.292,51.384c0.514-3.735-4.757-6.808-8.719-7.127c-1.889-0.152-3.851,0.136-5.641-0.487 c-3.877-1.351-5.426-6.361-9.21-7.956c-2.829-1.193-6.085-0.131-8.827,1.249c-2.742,1.38-5.452,3.117-8.515,3.313 c-2.665,0.171-5.559-0.818-7.89,0.484c-1.737,0.97-2.647,2.972-4.249,4.153c-1.567,1.155-3.61,1.4-5.554,1.506 c-1.944,0.106-3.956,0.125-5.726,0.936c-1.77,0.81-3.234,2.682-2.867,4.593L195.292,51.384z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{
              fill: "#EBEBEB",
            }}
            d="M460.804,272.087c0.855-6.212-7.911-11.321-14.499-11.852c-3.141-0.253-6.404,0.227-9.38-0.81 c-6.448-2.247-9.023-10.577-15.315-13.231c-4.704-1.984-10.119-0.218-14.678,2.077c-4.56,2.295-9.066,5.183-14.161,5.509 c-4.431,0.284-9.243-1.36-13.12,0.805c-2.889,1.613-4.402,4.942-7.065,6.906c-2.605,1.921-6.003,2.328-9.236,2.504 c-3.232,0.176-6.579,0.208-9.522,1.556c-2.943,1.348-5.378,4.46-4.767,7.639L460.804,272.087z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{
              fill: "#F5F5F5",
            }}
            d="M127.396,120.531c0.654-4.752-6.052-8.661-11.091-9.067c-2.403-0.194-4.899,0.174-7.176-0.62 c-4.933-1.719-6.903-8.092-11.716-10.122c-3.598-1.518-7.741-0.167-11.229,1.589c-3.488,1.756-6.936,3.965-10.833,4.215 c-3.39,0.217-7.071-1.04-10.037,0.616c-2.21,1.234-3.368,3.781-5.405,5.283c-1.993,1.47-4.592,1.781-7.065,1.916 c-2.473,0.135-5.033,0.159-7.284,1.19c-2.252,1.031-4.114,3.412-3.647,5.843L127.396,120.531z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#E0E0E0",
                }}
                points="392.868,379.382 370.62,475.978 380.026,475.955 402.324,380.293  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#E0E0E0",
                }}
                points="433.453,379.382 455.779,475.978 464.717,475.978 443.116,379.382  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#E0E0E0",
                }}
                points="377.851,306.831 390.519,373.336 404.914,373.336 391.382,306.831  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#E0E0E0",
                }}
                points="157.219,379.382 139.073,475.978 148.311,475.978 167.268,379.382  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#E0E0E0",
                }}
                points="195.377,379.382 216.123,475.451 225.25,475.451 205.218,379.382  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#E0E0E0",
                }}
                points="142.202,306.831 154.87,373.336 169.265,373.336 155.734,306.831  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#EBEBEB",
                }}
                points="113.7,300.785 116.291,312.877 405.341,312.877 401.968,300.785  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#EBEBEB",
                }}
                points="119.225,324.267 122.276,336.359 409.372,336.359 406.761,324.267  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                style={{
                  fill: "#EBEBEB",
                }}
                points="124.928,348.577 127.773,360.668 413.978,360.668 411.273,348.577  "
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <rect
                x={146.809}
                y={373.336}
                style={{
                  fill: "#EBEBEB",
                }}
                width={308.627}
                height={12.092}
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#EBEBEB",
                }}
                d="M330.381,223.601c-4.901,0-9.293-2.527-11.758-6.766c-2.472-4.252-2.487-9.337-0.042-13.603 l38.987-68.007c2.445-4.266,6.841-6.822,11.759-6.837c0.015,0,0.029,0,0.044,0c4.9,0,9.293,2.526,11.758,6.766l39.402,67.767 c2.471,4.251,2.487,9.337,0.041,13.603c-2.446,4.266-6.841,6.822-11.759,6.837l-78.391,0.24 C330.41,223.601,330.396,223.601,330.381,223.601z M369.371,132.45c-0.01,0-0.021,0-0.03,0 c-3.449,0.011-6.532,1.803-8.248,4.795l-38.987,68.007c-1.715,2.992-1.704,6.559,0.029,9.541 c1.729,2.973,4.81,4.745,8.247,4.745c0.01,0,0.02,0,0.03,0l78.389-0.24c3.45-0.011,6.533-1.803,8.248-4.795 c1.715-2.992,1.704-6.559-0.029-9.541l-39.403-67.767C375.889,134.222,372.809,132.45,369.371,132.45z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#EBEBEB",
              }}
              d="M361.721,161.873l1.825,25.362c0.149,2.074,1.821,3.711,3.897,3.817l0,0 c2.19,0.111,4.084-1.514,4.306-3.695l2.598-25.528c0.197-1.933-1.345-3.601-3.287-3.557l-6.189,0.14 C363.024,158.452,361.588,160.031,361.721,161.873z"
            />
          </g>
          <g>
            <path
              style={{
                fill: "#EBEBEB",
              }}
              d="M374.292,205.267c-1.05,3.255-4.54,5.044-7.795,3.993c-3.256-1.05-5.044-4.54-3.994-7.795 c1.05-3.256,4.54-5.044,7.796-3.994C373.554,198.521,375.342,202.012,374.292,205.267z"
            />
          </g>
        </g>
      </g>
    </g>
    <g
      id="Background_Simple"
      style={{
        display: "none",
      }}
    >
      <g
        style={{
          display: "inline",
        }}
      >
        <path
          style={{
            fill: "#F5F5F5",
          }}
          d="M435.384,90.104l-2.434-4.01c-20.516-33.808-57.609-54.188-96.717-51.715 c-5.027,0.318-10.033,0.999-14.971,2.056c-35.557,7.611-66.496,34.5-79.321,68.937c-7.542,20.253-9.649,43.571-23.962,59.647 c-18.627,20.922-50.251,21.579-78.055,20.712c-27.804-0.867-59.449-0.128-77.985,20.877 c-11.609,13.154-15.299,31.861-14.362,49.478c2.478,46.641,34.979,88.345,76.311,109.074 c41.332,20.729,89.996,22.586,135.232,13.467c63.37-12.774,123.634-47.855,159.857-102.038 C455.199,222.404,464.367,148.585,435.384,90.104z"
        />
      </g>
    </g>
    <g id="Floor">
      <g>
        <g>
          <path
            style={{
              fill: "#263238",
            }}
            d="M469.807,475.857c0,0.144-98.419,0.26-219.796,0.26c-121.42,0-219.817-0.117-219.817-0.26 s98.397-0.26,219.817-0.26C371.388,475.596,469.807,475.713,469.807,475.857z"
          />
        </g>
      </g>
    </g>
    <g id="Device">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{
                                  fill: "#263238",
                                }}
                                d="M317.518,368.575l-100.957-0.063c-12.142-0.007-21.981-10.355-21.979-23.115 l0.049-265.984c0.002-12.767,9.855-23.114,22.004-23.107l100.957,0.062c12.142,0.008,21.98,10.355,21.978,23.116 l-0.05,265.985C339.52,358.237,329.667,368.583,317.518,368.575z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{
                                  fill: "#FFFFFF",
                                }}
                                d="M317.487,65.776l-19.574-0.012c-2.625-0.001-4.754,2.336-4.754,5.219l-0.001,3.731 c0,2.884-2.129,5.221-4.753,5.219l-38.154-0.024c-2.625-0.001-4.752-2.341-4.752-5.225l0.001-3.731 c0-2.884-2.128-5.223-4.753-5.225l-6.884-0.005l-17.132-0.01c-8.508-0.005-15.409,7.241-15.41,16.183l-0.048,259.62 c-0.002,8.938,6.889,16.184,15.393,16.19l100.755,0.062c8.508,0.005,15.408-7.241,15.41-16.182l0.048-259.621 C332.88,73.028,325.99,65.782,317.487,65.776z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#E0E0E0",
                  }}
                  d="M228.799,333.265v6.357c0,0.551-0.607,0.886-1.071,0.59l-4.997-3.178 c-0.432-0.275-0.432-0.906,0-1.181l4.997-3.178C228.192,332.379,228.799,332.714,228.799,333.265z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#E0E0E0",
                  }}
                  d="M269.863,336.385c0,2.425-1.962,4.391-4.382,4.391c-2.42,0-4.381-1.966-4.381-4.391 c0-2.425,1.962-4.391,4.381-4.391C267.901,331.993,269.863,333.959,269.863,336.385z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#E0E0E0",
                  }}
                  d="M307.532,340.667h-4.345c-1.033,0-1.869-0.837-1.869-1.87v-4.363 c0-1.033,0.837-1.87,1.869-1.87h4.345c1.033,0,1.87,0.837,1.87,1.87v4.363C309.402,339.83,308.565,340.667,307.532,340.667z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <ellipse
              transform="matrix(0.2347 -0.9721 0.9721 0.2347 29.2342 398.0749)"
              style={{
                fill: "#F5F5F5",
              }}
              cx={267.442}
              cy={180.47}
              rx={49.742}
              ry={49.742}
            />
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#455A64",
                    }}
                    d="M269.672,202.385c-0.01,2.27-1.859,4.102-4.129,4.091c-2.27-0.011-4.102-1.859-4.091-4.129 c0.01-2.27,1.859-4.102,4.129-4.091C267.852,198.266,269.683,200.115,269.672,202.385z"
                  />
                </g>
                <g>
                  <g>
                    <path
                      style={{
                        fill: "#455A64",
                      }}
                      d="M257.348,197.102c4.514-4.739,11.85-4.884,16.479-0.217c2.098,2.115,5.383-1.138,3.284-3.254 c-6.352-6.402-16.779-6.364-23.017,0.187C252.043,195.971,255.291,199.262,257.348,197.102L257.348,197.102z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      style={{
                        fill: "#455A64",
                      }}
                      d="M243.387,177.97c6.25-5.403,13.711-8.804,22.066-8.825 c8.281-0.021,16.447,2.919,22.512,8.615c2.167,2.035,5.459-1.212,3.284-3.254c-6.947-6.525-16.265-10.008-25.775-9.984 c-9.614,0.024-18.159,3.954-25.341,10.163C237.878,176.635,241.144,179.909,243.387,177.97L243.387,177.97z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      style={{
                        fill: "#455A64",
                      }}
                      d="M248.897,188.572c4.19-4.53,10.062-7.367,16.268-7.508c6.375-0.145,12.53,2.48,16.962,7.034 c2.079,2.135,5.362-1.119,3.284-3.254c-5.245-5.388-12.676-8.575-20.224-8.403c-7.382,0.169-14.536,3.434-19.543,8.847 C243.625,187.469,246.871,190.762,248.897,188.572L248.897,188.572z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <ellipse
                      transform="matrix(0.1741 -0.9847 0.9847 0.1741 76.6011 425.4913)"
                      style={{
                        fill: "#FF725E",
                      }}
                      cx={291.962}
                      cy={167.079}
                      rx={12.614}
                      ry={12.614}
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <rect
                          x={291.007}
                          y={161.009}
                          transform="matrix(0.7071 0.7071 -0.7071 0.7071 203.4598 -157.8496)"
                          style={{
                            fill: "#FFFFFF",
                          }}
                          width={2.528}
                          height={11.327}
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <rect
                          x={291.007}
                          y={161.009}
                          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -32.2513 255.4843)"
                          style={{
                            fill: "#FFFFFF",
                          }}
                          width={2.528}
                          height={11.327}
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#E0E0E0",
                }}
                d="M264.108,93.55c0.63-0.381,1.301-0.67,1.993-0.866l0.411-1.973l3.333-0.058l0.48,1.957 c0.698,0.172,1.379,0.437,2.022,0.796l1.684-1.104l2.398,2.315l-1.044,1.722c0.381,0.63,0.67,1.301,0.866,1.993l1.972,0.41 l0.059,3.333l-1.957,0.48c-0.172,0.698-0.437,1.379-0.796,2.022l1.104,1.685l-2.315,2.398l-1.722-1.044 c-0.63,0.381-1.301,0.67-1.993,0.866l-0.411,1.972l-3.333,0.059l-0.48-1.957c-0.698-0.172-1.379-0.437-2.022-0.796 l-1.684,1.104l-2.398-2.315l1.044-1.722c-0.381-0.63-0.67-1.301-0.866-1.993l-1.972-0.411l-0.058-3.333l1.957-0.48 c0.172-0.698,0.437-1.379,0.796-2.022l-1.104-1.684l2.315-2.398L264.108,93.55L264.108,93.55z M265.884,97.99 c-1.372,1.421-1.332,3.684,0.089,5.056c1.421,1.372,3.684,1.332,5.056-0.089c1.372-1.421,1.332-3.685-0.089-5.056 S267.256,96.569,265.884,97.99z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M221.818,101.02c-1.052,0-2.385-0.024-3.718-0.048c-1.591-0.029-3.182-0.056-4.288-0.045 c-0.002,0-0.004,0-0.006,0c-0.273,0-0.497-0.22-0.5-0.494c-0.003-0.276,0.218-0.502,0.494-0.506 c1.113-0.014,2.716,0.016,4.318,0.045c1.591,0.03,3.183,0.058,4.288,0.045c0.002,0,0.004,0,0.006,0 c0.273,0,0.497,0.22,0.5,0.494c0.003,0.276-0.218,0.503-0.494,0.506C222.232,101.019,222.031,101.02,221.818,101.02z"
            />
          </g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M216.854,104.359c-0.138,0-0.275-0.057-0.374-0.168l-3.047-3.432 c-0.172-0.194-0.168-0.489,0.012-0.677l2.971-3.12c0.192-0.199,0.509-0.206,0.707-0.018c0.2,0.19,0.208,0.507,0.018,0.707 l-2.654,2.787l2.742,3.088c0.183,0.206,0.165,0.522-0.042,0.706C217.091,104.317,216.972,104.359,216.854,104.359z"
            />
          </g>
        </g>
        <g>
          <g>
            <circle
              style={{
                fill: "#263238",
              }}
              cx={319.928}
              cy={96.541}
              r={1.554}
            />
          </g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M321.482,101.441c0,0.858-0.696,1.554-1.554,1.554c-0.858,0-1.554-0.696-1.554-1.554 c0-0.858,0.696-1.554,1.554-1.554C320.786,99.887,321.482,100.583,321.482,101.441z"
            />
          </g>
          <g>
            <circle
              style={{
                fill: "#263238",
              }}
              cx={319.928}
              cy={106.341}
              r={1.554}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M233.756,248.889c0,0.144-3.176,0.26-7.092,0.26c-3.918,0-7.093-0.117-7.093-0.26 c0-0.144,3.175-0.26,7.093-0.26C230.58,248.629,233.756,248.746,233.756,248.889z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M259.227,265.489c0,0.144-8.718,0.26-19.47,0.26c-10.756,0-19.472-0.117-19.472-0.26 c0-0.144,8.716-0.26,19.472-0.26C250.509,265.229,259.227,265.345,259.227,265.489z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M259.227,281.98c0,0.144-8.718,0.26-19.47,0.26c-10.756,0-19.472-0.117-19.472-0.26 s8.716-0.26,19.472-0.26C250.509,281.72,259.227,281.837,259.227,281.98z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#EBEBEB",
              }}
              d="M310.709,271.717h-14.25c-3.439,0-6.227-2.788-6.227-6.227v0c0-3.439,2.788-6.227,6.227-6.227 h14.25c3.439,0,6.227,2.788,6.227,6.227v0C316.937,268.928,314.149,271.717,310.709,271.717z"
            />
          </g>
          <g>
            <path
              style={{
                fill: "#E0E0E0",
              }}
              d="M301.6,265.489c0,2.881-2.335,5.216-5.216,5.216c-2.881,0-5.216-2.335-5.216-5.216 c0-2.881,2.335-5.216,5.216-5.216C299.264,260.273,301.6,262.608,301.6,265.489z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#EBEBEB",
              }}
              d="M310.709,288.208h-14.25c-3.439,0-6.227-2.788-6.227-6.227l0,0c0-3.439,2.788-6.227,6.227-6.227 h14.25c3.439,0,6.227,2.788,6.227,6.227l0,0C316.937,285.42,314.149,288.208,310.709,288.208z"
            />
          </g>
          <g>
            <circle
              style={{
                fill: "#E0E0E0",
              }}
              cx={296.384}
              cy={281.98}
              r={5.216}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#263238",
              }}
              d="M259.227,298.583c0,0.144-8.718,0.26-19.47,0.26c-10.756,0-19.472-0.117-19.472-0.26 c0-0.144,8.716-0.26,19.472-0.26C250.509,298.323,259.227,298.439,259.227,298.583z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#EBEBEB",
              }}
              d="M310.709,306.655h-14.25c-3.439,0-6.227-2.788-6.227-6.227l0,0c0-3.439,2.788-6.227,6.227-6.227 h14.25c3.439,0,6.227,2.788,6.227,6.227l0,0C316.937,303.867,314.149,306.655,310.709,306.655z"
            />
          </g>
          <g>
            <circle
              style={{
                fill: "#E0E0E0",
              }}
              cx={296.384}
              cy={300.427}
              r={5.216}
            />
          </g>
        </g>
      </g>
    </g>
    <g id="Sun">
      <g>
        <g>
          <g>
            <ellipse
              transform="matrix(0.1594 -0.9872 0.9872 0.1594 268.0351 476.1047)"
              style={{
                fill: "#FF725E",
              }}
              cx={413.582}
              cy={80.664}
              rx={16.297}
              ry={16.297}
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M411.261,117.105c-0.095,0-0.172-3.01-0.172-6.722c0-3.713,0.077-6.722,0.172-6.722 c0.095,0,0.172,3.009,0.172,6.722C411.433,114.095,411.356,117.105,411.261,117.105z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M401.87,99.871c0.082,0.047-1.451,2.855-3.423,6.271c-1.973,3.417-3.639,6.147-3.721,6.1 c-0.082-0.047,1.45-2.855,3.424-6.272C400.122,102.555,401.788,99.824,401.87,99.871z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M392.231,93.87c0.059,0.074-1.645,1.527-3.806,3.244c-2.161,1.718-3.961,3.05-4.02,2.976 c-0.059-0.074,1.645-1.526,3.806-3.244C390.373,95.128,392.172,93.796,392.231,93.87z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M427.519,110.483c-0.049,0.008-0.195-0.628-0.43-1.654c-0.234-1.026-0.567-2.44-0.983-3.989 c-0.417-1.549-0.839-2.939-1.151-3.945c-0.311-1.005-0.504-1.629-0.458-1.646c0.042-0.016,0.316,0.578,0.687,1.568 c0.372,0.989,0.833,2.376,1.253,3.934c0.418,1.558,0.715,2.989,0.889,4.031C427.502,109.824,427.564,110.475,427.519,110.483z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M444.049,98.754c-0.062,0.071-2.219-1.712-4.817-3.983c-2.599-2.272-4.654-4.17-4.591-4.242 c0.062-0.071,2.219,1.712,4.817,3.983C442.056,96.783,444.112,98.682,444.049,98.754z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M450.381,82.634c-0.011,0.049-0.758-0.082-1.966-0.283c-1.207-0.201-2.877-0.46-4.73-0.673 c-1.854-0.212-3.539-0.338-4.76-0.416c-1.221-0.078-1.979-0.119-1.978-0.17c0-0.044,0.759-0.089,1.988-0.073 c1.228,0.016,2.926,0.104,4.79,0.317c1.863,0.215,3.536,0.513,4.736,0.776C449.661,82.376,450.391,82.591,450.381,82.634z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M450.401,66.784c0.022,0.092-3.506,1.031-7.88,2.096c-4.375,1.066-7.939,1.855-7.962,1.763 c-0.022-0.092,3.505-1.03,7.88-2.096C446.814,67.481,450.379,66.691,450.401,66.784z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M431.985,48.315c0.079,0.052-1.609,2.745-3.77,6.014c-2.161,3.27-3.977,5.877-4.056,5.825 c-0.079-0.052,1.609-2.744,3.77-6.014C430.09,50.871,431.906,48.263,431.985,48.315z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M413.709,44.458c0.049,0.003,0.043,0.857,0.024,2.237c-0.02,1.38-0.047,3.285-0.045,5.39 c0.003,2.105,0.034,4.011,0.057,5.391c0.023,1.38,0.03,2.235-0.019,2.237c-0.046,0.003-0.139-0.848-0.224-2.228 c-0.085-1.38-0.155-3.289-0.158-5.4c-0.002-2.11,0.064-4.019,0.146-5.399C413.572,45.306,413.664,44.455,413.709,44.458z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M399.869,61.695c-0.081,0.049-1.798-2.613-3.834-5.945c-2.037-3.334-3.622-6.075-3.542-6.125 c0.081-0.049,1.797,2.612,3.834,5.946C398.365,58.903,399.95,61.645,399.869,61.695z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M391.602,68.721c-0.01,0.045-0.673-0.052-1.733-0.275c-1.06-0.222-2.514-0.578-4.099-1.045 c-1.585-0.468-3-0.959-4.01-1.347c-1.011-0.388-1.621-0.667-1.605-0.709c0.017-0.046,0.656,0.152,1.685,0.48 c1.028,0.329,2.449,0.781,4.027,1.247c1.578,0.465,3.016,0.856,4.059,1.138C390.968,68.493,391.612,68.673,391.602,68.721z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M387.99,81.242c0.009,0.094-2.32,0.396-5.201,0.673c-2.882,0.277-5.225,0.425-5.234,0.331 c-0.009-0.094,2.319-0.395,5.201-0.672C385.637,81.296,387.981,81.148,387.99,81.242z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Plants">
      <g>
        <g>
          <g>
            <path
              style={{
                fill: "#455A64",
              }}
              d="M376.877,371.736c4.862,34.624,9.725,69.247,14.587,103.87l3.796,0.124 C392.839,440.451,390.26,404.469,376.877,371.736z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#455A64",
              }}
              d="M417.387,366.872c-10.189,11.161-14.286,26.514-16.867,41.405 c-3.66,21.117-3.992,46.05-2.895,67.454l5.324,0.118C400.792,438.88,405.79,402.041,417.387,366.872z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#455A64",
              }}
              d="M379.558,433.187c-1.171-5.145-2.51-10.381-5.447-14.764 c-3.968-5.921-10.433-9.666-16.938-12.581c-6.505-2.915-13.348-5.247-19.224-9.282c6.557,10.752,21.441,13.614,29.022,23.671 c3.771,5.004,5.336,11.281,6.814,17.37c3.223,13.277,5.206,24.768,8.429,38.045h4.313 C385.104,461.219,382.776,447.323,379.558,433.187z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#455A64",
              }}
              d="M406.836,475.73c0,0,3.654-41.254,6.362-48.827c2.708-7.574,28.314-28.501,28.068-27.517 c-0.246,0.985-19.204,23.882-21.912,28.56c-2.708,4.678-7.386,47.784-7.386,47.784H406.836z"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M285.816,432.59c2.046,0.734,3.629,2.479,4.497,4.472c0.867,1.993,1.078,4.219,0.974,6.391 c-0.202,4.201-2.298,8.565-4.633,12.064c-3.711-2.837-4.867-8.131-5.284-10.854C280.71,440.359,282.13,432.181,285.816,432.59"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M291.183,462.134c-0.715-2.321,0.097-4.97,1.802-6.7c1.704-1.73,4.188-2.576,6.616-2.548 c1.123,0.013,2.351,0.26,3.051,1.138c0.654,0.821,0.665,2.026,0.24,2.986c-0.425,0.96-1.22,1.708-2.071,2.323 C297.903,461.44,294.691,462.943,291.183,462.134"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#263238",
                  }}
                  d="M287.511,475.215c-0.021-0.004,0.007-0.311,0.07-0.864c0.071-0.61,0.163-1.397,0.274-2.343 c0.262-1.961,0.581-4.766,1.91-7.554c1.309-2.781,3.258-4.893,4.943-5.988c0.836-0.559,1.576-0.908,2.103-1.098 c0.259-0.107,0.473-0.155,0.613-0.199c0.142-0.04,0.217-0.056,0.221-0.046c0.029,0.066-1.198,0.371-2.814,1.52 c-1.623,1.12-3.503,3.207-4.791,5.941c-1.303,2.731-1.655,5.499-1.971,7.453c-0.149,0.984-0.271,1.782-0.37,2.331 C287.599,474.917,287.531,475.218,287.511,475.215z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#263238",
                  }}
                  d="M285.284,438.913c0.01-0.001,0.033,0.127,0.067,0.371c0.035,0.277,0.079,0.632,0.134,1.071 c0.111,0.93,0.253,2.279,0.416,3.946c0.325,3.335,0.71,7.948,1.021,13.051c0.309,5.105,0.484,9.732,0.564,13.081 c0.04,1.675,0.062,3.03,0.064,3.967c-0.001,0.443-0.002,0.8-0.003,1.079c-0.004,0.247-0.011,0.376-0.022,0.377 c-0.01,0-0.024-0.129-0.04-0.375c-0.014-0.278-0.032-0.635-0.054-1.077c-0.04-0.974-0.094-2.318-0.161-3.963 c-0.135-3.346-0.344-7.968-0.653-13.07c-0.31-5.1-0.661-9.713-0.931-13.051c-0.133-1.641-0.241-2.982-0.319-3.954 c-0.031-0.441-0.057-0.798-0.077-1.076C285.276,439.044,285.274,438.914,285.284,438.913z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M285.272,465.068c-1.127-4.994-4.824-9.335-9.576-11.243c-1.21-0.486-2.766-0.754-3.674,0.182 c-0.909,0.937-0.591,2.492-0.039,3.676c2.295,4.925,7.811,8.149,13.228,7.733"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#263238",
                  }}
                  d="M276.523,457.744c0.008-0.019,0.279,0.08,0.766,0.277c0.244,0.097,0.543,0.217,0.871,0.394 c0.328,0.177,0.722,0.344,1.116,0.603c0.401,0.246,0.847,0.506,1.286,0.844c0.459,0.312,0.91,0.695,1.382,1.101 c0.923,0.835,1.863,1.843,2.687,3.026c0.813,1.19,1.426,2.426,1.874,3.588c0.206,0.589,0.404,1.147,0.524,1.689 c0.154,0.533,0.231,1.044,0.31,1.508c0.095,0.462,0.101,0.89,0.141,1.261c0.041,0.371,0.038,0.693,0.034,0.956 c-0.004,0.525-0.017,0.814-0.038,0.814c-0.069,0.002-0.025-1.163-0.349-2.994c-0.091-0.456-0.179-0.957-0.341-1.479 c-0.129-0.531-0.333-1.077-0.542-1.653c-0.455-1.137-1.064-2.346-1.863-3.515c-0.809-1.161-1.727-2.156-2.625-2.99 c-0.46-0.406-0.898-0.79-1.344-1.106c-0.427-0.342-0.86-0.609-1.249-0.863C277.585,458.218,276.496,457.805,276.523,457.744z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="Character">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{
                              fill: "#455A64",
                            }}
                            d="M110.072,420.798l-28.129,23.071l28.525,35.143c3.19-2.93-4.826-26.582-4.826-26.582 l18.629-14.043L110.072,420.798z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            style={{
              opacity: 0.6,
            }}
          >
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#FFFFFF",
                              }}
                              d="M98.344,440.327c-0.738-0.529-1.884-0.378-2.443,0.337 c-0.559,0.716-0.416,1.882,0.313,2.425c0.728,0.542,2.11,0.403,2.585-0.371c0.476-0.774,0.232-2.135-0.605-2.486"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            style={{
              opacity: 0.6,
            }}
          >
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{
                              fill: "#FFFFFF",
                            }}
                            d="M82.053,443.773l2.83-2.343l26.14,34.84c0,0,0.576,2.121-0.675,2.83L82.053,443.773z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M106.282,452.854c-0.176,0.134-0.951-0.661-2.188-1.058 c-1.227-0.429-2.323-0.261-2.384-0.474c-0.095-0.185,1.138-0.762,2.628-0.255 C105.832,451.56,106.47,452.764,106.282,452.854z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M107.75,457.778c-0.135,0.174-1.03-0.332-2.229-0.326 c-1.199-0.029-2.103,0.461-2.234,0.284c-0.158-0.139,0.751-1.074,2.242-1.053 C107.019,456.69,107.91,457.641,107.75,457.778z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M105.037,463.452c-0.163-0.126,0.5-1.061,1.798-1.383 c1.296-0.332,2.323,0.177,2.239,0.365c-0.063,0.212-0.99,0.1-2.051,0.381 C105.956,463.071,105.192,463.609,105.037,463.452z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M110.173,449.183c-0.215,0.055-0.617-0.924-1.47-1.841 c-0.835-0.934-1.773-1.424-1.699-1.633c0.044-0.2,1.277,0.021,2.267,1.115 C110.269,447.909,110.376,449.158,110.173,449.183z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M113.663,444.961c-0.069,0.06-0.726-0.532-1.419-1.767 c-0.345-0.615-0.684-1.394-0.929-2.3c-0.122-0.453-0.22-0.938-0.284-1.446c-0.027-0.246-0.078-0.524-0.017-0.867 c0.024-0.336,0.392-0.813,0.849-0.82c0.416-0.039,0.791,0.184,1.003,0.433c0.219,0.252,0.337,0.505,0.445,0.734 c0.223,0.469,0.391,0.941,0.513,1.399c0.239,0.919,0.296,1.778,0.252,2.487c-0.093,1.428-0.508,2.227-0.589,2.195 c-0.12-0.032,0.095-0.865,0.045-2.2c-0.026-0.665-0.134-1.454-0.39-2.288c-0.13-0.416-0.297-0.842-0.508-1.263 c-0.205-0.452-0.459-0.77-0.702-0.731c-0.092,0.017-0.12,0.032-0.166,0.199c-0.035,0.162-0.011,0.393,0.015,0.636 c0.049,0.476,0.127,0.931,0.223,1.361c0.193,0.86,0.462,1.615,0.729,2.231 C113.268,444.188,113.758,444.889,113.663,444.961z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M113.236,444.938c-0.076-0.039,0.263-0.879,1.323-1.844 c0.528-0.477,1.242-0.962,2.122-1.324c0.441-0.178,0.921-0.324,1.433-0.419c0.514-0.076,1.183-0.202,1.8,0.401 c0.296,0.337,0.258,0.846,0.106,1.134c-0.144,0.309-0.329,0.498-0.499,0.704c-0.358,0.398-0.767,0.717-1.185,0.963 c-0.838,0.496-1.708,0.695-2.428,0.724c-1.468,0.052-2.254-0.497-2.208-0.559c0.039-0.127,0.856,0.202,2.158,0.017 c0.642-0.091,1.398-0.323,2.106-0.786c0.354-0.23,0.695-0.515,0.991-0.858c0.329-0.358,0.516-0.743,0.365-0.852 c-0.139-0.177-0.661-0.246-1.076-0.148c-0.462,0.075-0.901,0.193-1.308,0.338c-0.814,0.295-1.5,0.693-2.033,1.085 C113.832,444.305,113.338,445.01,113.236,444.938z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{
                              fill: "#455A64",
                            }}
                            d="M97.327,439.643l0.341,36.378l45.262-0.192c-0.283-4.323-23.736-12.904-23.736-12.904 l0.738-23.317L97.327,439.643z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            style={{
              opacity: 0.6,
            }}
          >
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#FFFFFF",
                              }}
                              d="M105.19,461.023c-0.875,0.243-1.476,1.231-1.269,2.115 c0.207,0.884,1.206,1.504,2.085,1.276c0.879-0.228,1.637-1.391,1.332-2.246c-0.305-0.855-1.518-1.519-2.316-1.087"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            style={{
              opacity: 0.6,
            }}
          >
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{
                              fill: "#FFFFFF",
                            }}
                            d="M97.662,475.875l-0.051-3.673l43.531,1.476c0,0,2.013,0.882,1.782,2.3L97.662,475.875z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M119.926,462.691c-0.006,0.221-1.111,0.326-2.196,1.041 c-1.103,0.687-1.66,1.647-1.863,1.56c-0.204-0.042,0.12-1.365,1.448-2.208 C118.636,462.23,119.973,462.488,119.926,462.691z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M124.682,464.634c0.052,0.214-0.904,0.594-1.651,1.532 c-0.774,0.916-0.959,1.927-1.179,1.919c-0.208,0.036-0.366-1.258,0.584-2.407 C123.377,464.522,124.677,464.424,124.682,464.634z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M127.403,470.305c-0.201,0.048-0.513-1.055,0.049-2.268 c0.554-1.217,1.594-1.699,1.688-1.516c0.126,0.181-0.543,0.834-0.989,1.837 C127.681,469.35,127.622,470.282,127.403,470.305z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M119.505,457.359c-0.092,0.202-1.107-0.099-2.356-0.009 c-1.251,0.065-2.221,0.488-2.337,0.299c-0.129-0.159,0.817-0.981,2.289-1.067 C118.573,456.485,119.613,457.185,119.505,457.359z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M118.404,451.993c0.003,0.091-0.87,0.231-2.267-0.002 c-0.695-0.117-1.514-0.341-2.375-0.718c-0.43-0.189-0.869-0.416-1.305-0.685c-0.209-0.133-0.457-0.267-0.687-0.53 c-0.246-0.229-0.388-0.815-0.106-1.175c0.23-0.349,0.639-0.501,0.966-0.51c0.334-0.012,0.604,0.054,0.851,0.114 c0.505,0.12,0.978,0.285,1.411,0.478c0.867,0.39,1.571,0.884,2.096,1.363c1.054,0.968,1.416,1.792,1.341,1.835 c-0.1,0.073-0.614-0.616-1.686-1.414c-0.535-0.397-1.217-0.807-2.027-1.131c-0.406-0.159-0.842-0.296-1.303-0.396 c-0.48-0.124-0.888-0.125-1.01,0.088c-0.044,0.082-0.05,0.114,0.051,0.254c0.104,0.129,0.299,0.255,0.505,0.387 c0.402,0.259,0.805,0.484,1.201,0.679c0.791,0.388,1.547,0.652,2.195,0.831 C117.554,451.816,118.408,451.873,118.404,451.993z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#263238",
                              }}
                              d="M118.118,452.311c-0.078,0.034-0.52-0.756-0.607-2.187 c-0.041-0.71,0.029-1.571,0.299-2.483c0.137-0.455,0.325-0.921,0.572-1.379c0.263-0.448,0.584-1.048,1.441-1.151 c0.448-0.019,0.821,0.329,0.95,0.628c0.151,0.306,0.182,0.568,0.236,0.83c0.085,0.529,0.077,1.047,0.008,1.527 c-0.138,0.964-0.529,1.767-0.958,2.345c-0.88,1.177-1.801,1.445-1.819,1.37c-0.075-0.111,0.694-0.541,1.366-1.671 c0.331-0.557,0.625-1.291,0.708-2.133c0.043-0.419,0.034-0.864-0.047-1.31c-0.073-0.481-0.255-0.868-0.435-0.818 c-0.225-0.003-0.606,0.361-0.79,0.746c-0.23,0.407-0.414,0.823-0.556,1.231c-0.28,0.819-0.4,1.603-0.429,2.264 C117.998,451.45,118.238,452.277,118.118,452.311z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      style={{
                        fill: "#263238",
                      }}
                      d="M101.205,228.909c0,0-6.342,6.792-9.458,13.3c-3.115,6.508-5.107,22.039-5.107,22.039 s-8.759,19.51-7.428,29.491c1.331,9.982,10.251,78.247,10.251,78.247s-0.441,66.364,5.877,78.196l1.41,6.387l25.516-4.53 l0,0l-0.159-3.83l6.027-130.124l15.355-88.586L101.205,228.909z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#263238",
                    }}
                    d="M157.515,248.259c0,0,15.256,83.991,16.601,98.574c1.345,14.583-50.582,91.443-50.582,91.443 l-23.746-24.727l34.08-63.351l-11.076-63.844l15.409-56.412l16.185,0.568L157.515,248.259z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#455A64",
                    }}
                    d="M91.383,429.521c0,0,0.012,0.642,0.182,1.857c0.156,1.217,0.456,3.007,0.879,5.318 c0.849,4.622,2.225,11.325,4.073,19.698l0.038,0.174l0.177-0.031c7.212-1.264,15.869-2.781,25.525-4.473l0.193-0.034 l0.008-0.197c0.71-17.513,1.558-38.445,2.467-60.893c0.942-24.154,1.815-46.55,2.532-64.924l-0.002,0.022 c1.913-14.761,3.495-26.967,4.602-35.508c0.534-4.243,0.953-7.574,1.242-9.87c0.133-1.118,0.236-1.984,0.308-2.594 c0.066-0.587,0.081-0.891,0.081-0.891s-0.064,0.298-0.153,0.882c-0.089,0.608-0.215,1.47-0.378,2.584 c-0.319,2.292-0.782,5.618-1.371,9.853c-1.155,8.535-2.807,20.732-4.803,35.481l-0.001,0.01l0,0.011 c-0.746,18.373-1.654,40.767-2.634,64.92c-0.877,22.449-1.694,43.382-2.378,60.896l0.201-0.23 c-9.649,1.735-18.299,3.29-25.506,4.586l0.215,0.143c-1.896-8.358-3.328-15.048-4.239-19.654 c-0.453-2.303-0.785-4.085-0.974-5.295c-0.105-0.604-0.164-1.065-0.206-1.375C91.418,429.678,91.383,429.521,91.383,429.521z "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <path
            style={{
              fill: "#455A64",
            }}
            d="M61.749,194.145c0,0,15.801-34.057,19.532-35.869c3.731-1.812,14.445-2.414,15.902-0.38 l-28.321,40.781L61.749,194.145z"
          />
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#455A64",
              }}
              d="M62.572,189.546c-0.699-0.383-1.517-0.49-2.29-0.296c-0.008,0.002-0.016,0.004-0.023,0.006 c-4.904,1.229-22.848,6.042-24.849,11.135c-2.321,5.906,11.874,82.714,13.703,84.793c1.828,2.078,30.05-1.415,30.913-4.691 c0.864-3.276-9.18-86.06-9.18-86.06L62.572,189.546z"
            />
          </g>
          <g>
            <path
              style={{
                fill: "#455A64",
              }}
              d="M40.301,241.865c0,0-1.599,0.092-3.19,3.629c-1.591,3.537,1.211,32.187,3.322,35.118 c2.111,2.931,8.262,3.548,8.262,3.548L40.301,241.865z"
            />
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M71.75,282.049c-0.008,0.005-0.056-0.06-0.143-0.191c-0.101-0.159-0.224-0.353-0.375-0.59 c-0.333-0.517-0.773-1.31-1.295-2.355c-1.04-2.09-2.283-5.26-3.225-9.334c-0.959-4.067-1.663-8.998-2.239-14.471 c-0.584-5.481-1.225-11.499-1.899-17.818c-1.361-12.639-2.496-24.105-2.684-32.449c-0.105-4.169-0.018-7.545,0.109-9.872 c0.058-1.164,0.131-2.066,0.189-2.677c0.028-0.279,0.051-0.507,0.069-0.695c0.019-0.156,0.032-0.236,0.041-0.235 c0.009,0.001,0.013,0.082,0.012,0.239c-0.005,0.188-0.011,0.417-0.018,0.698c-0.022,0.656-0.053,1.55-0.091,2.679 c-0.064,2.326-0.095,5.694,0.057,9.852c0.282,8.318,1.473,19.768,2.834,32.406c0.659,6.318,1.286,12.335,1.857,17.816 c0.55,5.479,1.214,10.391,2.121,14.445c0.89,4.06,2.071,7.224,3.049,9.329c0.491,1.052,0.9,1.858,1.203,2.39 c0.132,0.247,0.24,0.449,0.329,0.615C71.724,281.971,71.757,282.045,71.75,282.049z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M62.746,236.497c0.009,0.078-1.277,0.272-3.344,0.63c-2.067,0.357-4.911,0.905-8.015,1.672 c-3.104,0.77-5.876,1.613-7.87,2.261c-1.995,0.647-3.223,1.075-3.251,1.003c-0.013-0.033,0.278-0.181,0.816-0.42 c0.537-0.241,1.328-0.561,2.314-0.93c1.972-0.74,4.743-1.644,7.866-2.419c3.122-0.771,5.996-1.265,8.085-1.53 c1.045-0.133,1.893-0.22,2.481-0.257C62.415,236.466,62.742,236.461,62.746,236.497z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M39.933,238.689c0.018-0.002,0.059,0.16,0.12,0.47c0.066,0.371,0.147,0.82,0.243,1.362 c0.211,1.266,0.494,2.96,0.837,5.016c0.708,4.235,1.736,10.075,3.024,16.496c1.292,6.423,2.601,12.208,3.583,16.387 c0.478,2.029,0.872,3.701,1.166,4.95c0.12,0.537,0.219,0.982,0.302,1.35c0.063,0.31,0.088,0.475,0.071,0.48 c-0.017,0.005-0.076-0.152-0.172-0.453c-0.108-0.361-0.239-0.799-0.396-1.326c-0.336-1.154-0.79-2.832-1.327-4.913 c-1.075-4.161-2.442-9.941-3.736-16.372c-1.29-6.43-2.262-12.289-2.877-16.542c-0.308-2.126-0.537-3.85-0.674-5.044 c-0.058-0.547-0.107-1.001-0.147-1.376C39.921,238.858,39.915,238.691,39.933,238.689z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M60.022,204.112c-0.068-0.01-0.025-0.824,0.159-2.129c0.183-1.304,0.522-3.094,1.035-5.038 c0.515-1.944,1.105-3.669,1.59-4.893c0.485-1.225,0.85-1.954,0.914-1.929c0.144,0.057-0.988,3.096-2.001,6.954 C60.693,200.932,60.175,204.134,60.022,204.112z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M159.145,131.186c-0.164-2.891-0.525-16.956-0.693-19.095c-0.225-2.886-1.418-5.765-3.538-7.736 c-2.12-1.971-5.419-2.815-8.023-1.548l0,0c-1.722,0.435-3.068,1.766-3.549,3.475c-5.921,21.05-4.986,43.463-7.601,65.232 c7.726,1.257,18.125,0.743,25.942,0.333C161.683,154.466,160.212,149.951,159.145,131.186z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#455A64",
                  }}
                  d="M155.887,160.864c-0.018,0-0.038-0.193-0.061-0.562c-0.02-0.425-0.045-0.959-0.075-1.614 c-0.045-1.471-0.107-3.483-0.183-5.938c-0.106-5.077-0.251-11.973-0.41-19.592c-0.059-3.825-0.114-7.465-0.165-10.789 c-0.02-1.655-0.039-3.229-0.057-4.708c-0.013-1.472-0.05-2.838-0.28-4.053c-0.398-2.465-1.812-4.105-3.012-4.759 c-1.215-0.681-2.054-0.551-2.038-0.621c0-0.009,0.048-0.016,0.143-0.022c0.095-0.005,0.238-0.017,0.424,0.004 c0.371,0.026,0.927,0.137,1.565,0.466c0.634,0.329,1.34,0.884,1.945,1.705c0.614,0.815,1.074,1.912,1.324,3.159 c0.26,1.249,0.317,2.642,0.349,4.115c0.033,1.478,0.067,3.053,0.104,4.707c0.063,3.323,0.132,6.963,0.205,10.788 c0.102,7.621,0.194,14.517,0.262,19.595c0.009,2.456,0.016,4.468,0.021,5.94c-0.008,0.656-0.015,1.191-0.02,1.616 C155.918,160.669,155.905,160.863,155.887,160.864z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#455A64",
                  }}
                  d="M148.862,152.884c-0.035,0-0.067-0.451-0.094-1.268c-0.027-0.817-0.045-1.999-0.046-3.459 c-0.007-2.92,0.058-6.954,0.168-11.406c0.122-4.4,0.233-8.379,0.317-11.4c0.046-1.38,0.084-2.537,0.115-3.455 c0.033-0.816,0.068-1.267,0.103-1.267c0.035,0,0.067,0.451,0.094,1.268c0.027,0.817,0.045,1.999,0.046,3.459 c0.007,2.92-0.058,6.954-0.169,11.408c-0.122,4.398-0.233,8.377-0.317,11.398c-0.046,1.38-0.084,2.537-0.115,3.455 C148.932,152.432,148.897,152.884,148.862,152.884z"
                />
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#FF725E",
              }}
              d="M97.938,158.276c-1.01,0-19.86,1.347-24.236,21.543c-4.376,20.196-5.641,48.498-5.641,48.498 l20.452-2.047L97.938,158.276z"
            />
          </g>
          <g>
            <path
              style={{
                fill: "#FF725E",
              }}
              d="M107.198,153.809l3.326-6.133l28.946-0.784l1.961,5.223l17.088,6.161l-1.34,75.736l12.655,69.904 c0,0-0.125,10.058-33.867,13.287L133.3,280.66l-16.85-0.396l0.457,35.688c0,0-29.741-0.482-39.166-7.214 c0,0-2.857-34.007,5.258-58.487c7.106-21.437,9.878-26.984,9.878-26.984l0.612-64.261L107.198,153.809z"
            />
          </g>
          <g
            style={{
              opacity: 0.3,
            }}
          >
            <g>
              <path d="M98.243,159.147c-5.835,24.945-11.669,49.891-17.503,74.836l5.928,6.287c6.096-2.722,10.287-9.246,10.229-15.921 c-0.03-3.457-1.094-6.833-1.237-10.287c-0.323-7.799,3.993-14.949,5.963-22.502 C104.413,180.865,102.405,169.387,98.243,159.147z" />
            </g>
          </g>
          <g>
            <path
              style={{
                fill: "#FF725E",
              }}
              d="M154.907,157.311c6.958,1.284,12.398,8.691,13.977,16.63c0.924,4.647,3.634,27.07,3.634,27.07 l1.492,8.214c0,0,1.249,28.257-4.744,31.41c-6.999,3.683-11.014-0.356-13.318-3.319c-2.304-2.962-4.785-22.22-4.785-22.22 l2.633-46.079L154.907,157.311z"
            />
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M158.222,168.646c0.018,0.003-0.011,0.282-0.08,0.811c-0.082,0.575-0.193,1.344-0.331,2.309 c-0.318,2.054-0.764,4.937-1.313,8.477c-0.576,3.592-1.257,7.845-2.013,12.562c-0.385,2.365-0.79,4.845-1.209,7.416 c-0.429,2.571-0.938,5.219-1.159,7.956c-0.137,1.616-0.147,3.217-0.02,4.718l-0.005-0.033 c2.167,9.703,4.199,18.28,5.723,24.419c0.773,3.026,1.399,5.476,1.845,7.224c0.213,0.811,0.383,1.457,0.512,1.946 c0.113,0.445,0.164,0.681,0.148,0.685c-0.016,0.005-0.098-0.221-0.241-0.658c-0.151-0.483-0.35-1.122-0.6-1.923 c-0.498-1.68-1.195-4.127-1.999-7.189c-1.614-6.122-3.708-14.687-5.892-24.393l-0.004-0.017l-0.001-0.017 c-0.133-1.536-0.123-3.168,0.016-4.807c0.225-2.775,0.74-5.435,1.175-7.999c0.429-2.568,0.843-5.046,1.238-7.409 c0.797-4.71,1.516-8.957,2.123-12.544c0.616-3.529,1.118-6.403,1.476-8.451c0.179-0.958,0.321-1.722,0.427-2.293 C158.141,168.917,158.204,168.644,158.222,168.646z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#455A64",
                }}
                d="M83.983,163.035l5.314,70.922c0,0,9.922-45.224,10.328-56.792s-2.011-19.722-2.011-19.722 S87.997,155.933,83.983,163.035z"
              />
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#263238",
                  }}
                  d="M80.943,158.502c0.027-0.038,0.747,0.207,1.768,0.972c0.509,0.393,0.975,1.069,1.236,1.866 c0.279,0.788,0.446,1.697,0.53,2.639c0.172,1.885,0.16,3.584,0.164,4.811c-0.008,1.227-0.007,1.984-0.078,1.991 c-0.072,0.008-0.204-0.75-0.29-1.978c-0.098-1.226-0.145-2.925-0.315-4.774c-0.163-1.831-0.585-3.508-1.492-4.28 C81.576,158.967,80.887,158.58,80.943,158.502z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M117.72,161.075c-0.023,0.023-0.233-0.196-0.6-0.62c-0.366-0.424-0.89-1.053-1.538-1.85 l0.297-0.043c-0.722,2.09-1.813,5.122-3.071,8.519l-0.177,0.479l-0.274-0.413c-0.147-0.222-0.323-0.452-0.466-0.699 c-0.947-1.599-1.529-3.241-2.074-4.718c-0.527-1.485-0.961-2.831-1.335-3.958c-0.372-1.127-0.68-2.035-0.908-2.657 c-0.223-0.624-0.357-0.966-0.321-0.984c0.028-0.014,0.225,0.301,0.508,0.906c0.288,0.603,0.651,1.498,1.068,2.612 c0.425,1.156,0.909,2.474,1.438,3.915c0.564,1.455,1.163,3.085,2.068,4.612c0.137,0.236,0.299,0.447,0.436,0.657l-0.451,0.066 c1.266-3.394,2.42-6.403,3.238-8.457l0.115-0.289l0.182,0.246C117.064,160.027,117.766,161.029,117.72,161.075z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M140.719,156.193c0.032,0.015-0.095,0.353-0.354,0.949c-0.259,0.596-0.65,1.452-1.155,2.501 c-0.505,1.049-1.123,2.292-1.849,3.655c-0.74,1.358-1.533,2.859-2.618,4.325l-0.331,0.448l-0.128-0.548 c-0.017-0.073-0.036-0.153-0.055-0.233c-0.818-3.492-1.531-6.663-2.025-8.973l0.32,0.079c-0.852,0.936-1.783,1.34-2.299,1.748 c-0.537,0.395-0.788,0.675-0.82,0.646c-0.023-0.017,0.177-0.346,0.692-0.805c0.502-0.474,1.397-0.933,2.147-1.832l0.23-0.276 l0.091,0.355c0.584,2.288,1.353,5.446,2.171,8.938c0.019,0.08,0.037,0.159,0.054,0.233l-0.459-0.1 c1.04-1.402,1.856-2.902,2.611-4.235c0.715-1.322,1.369-2.531,1.943-3.592c0.506-0.943,0.939-1.751,1.301-2.425 C140.5,156.484,140.687,156.177,140.719,156.193z"
              />
            </g>
          </g>
          <g>
            <path
              style={{
                fill: "#FFFFFF",
              }}
              d="M118.222,160.789c0,0-2.808,36.504-3.54,41.197c-0.732,4.692,1.768,78.278,1.768,78.278 l16.442,1.052c0,0,0.171-60.75,0-61.169c-0.171-0.419,0.076-42.891,0.076-42.891l-3.733-16.921l-6.357,7.533L118.222,160.789z"
            />
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M136.569,195.488c0.039,0.078-0.575,0.213-0.871,1.003c-0.136,0.373-0.179,0.896,0.076,1.32 c0.238,0.43,0.823,0.652,1.408,0.544c0.585-0.107,1.052-0.524,1.122-1.011c0.086-0.487-0.14-0.961-0.401-1.26 c-0.558-0.632-1.18-0.539-1.172-0.626c-0.005-0.026,0.131-0.101,0.402-0.094c0.268,0.004,0.667,0.13,1.032,0.461 c0.348,0.327,0.696,0.888,0.612,1.591c-0.071,0.717-0.746,1.323-1.501,1.45c-0.751,0.152-1.597-0.174-1.92-0.818 c-0.33-0.627-0.205-1.275,0.003-1.705c0.223-0.44,0.55-0.7,0.799-0.8C136.411,195.439,136.565,195.462,136.569,195.488z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M136.569,227.353c0.039,0.078-0.575,0.213-0.871,1.003c-0.136,0.373-0.179,0.896,0.076,1.32 c0.238,0.43,0.823,0.652,1.408,0.543c0.585-0.107,1.052-0.524,1.122-1.011c0.086-0.487-0.14-0.961-0.401-1.26 c-0.558-0.632-1.18-0.539-1.172-0.626c-0.005-0.026,0.131-0.101,0.402-0.094c0.268,0.004,0.667,0.13,1.032,0.461 c0.348,0.327,0.696,0.888,0.612,1.591c-0.071,0.717-0.746,1.323-1.501,1.45c-0.751,0.152-1.597-0.174-1.92-0.818 c-0.33-0.627-0.205-1.275,0.003-1.705c0.223-0.44,0.55-0.7,0.799-0.8C136.411,227.305,136.565,227.327,136.569,227.353z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M138.027,265.893c0.039,0.078-0.575,0.213-0.871,1.003c-0.136,0.373-0.179,0.896,0.076,1.32 c0.238,0.43,0.823,0.652,1.408,0.544c0.585-0.107,1.052-0.524,1.122-1.011c0.086-0.487-0.14-0.961-0.401-1.26 c-0.558-0.632-1.18-0.539-1.172-0.626c-0.005-0.026,0.131-0.101,0.402-0.094c0.268,0.004,0.667,0.13,1.032,0.461 c0.348,0.327,0.696,0.888,0.612,1.591c-0.071,0.717-0.746,1.324-1.501,1.45c-0.751,0.152-1.597-0.174-1.92-0.818 c-0.33-0.627-0.205-1.275,0.003-1.705c0.223-0.44,0.55-0.7,0.799-0.8C137.869,265.845,138.023,265.867,138.027,265.893z"
              />
            </g>
          </g>
          <g
            style={{
              opacity: 0.3,
            }}
          >
            <g>
              <path d="M159.34,195.204c-0.359-2.911-1.132-5.768-1.325-8.695c-0.23-3.496,0.371-7.052-0.315-10.488 c-2.38,3.969-2.916,8.768-2.935,13.396c-0.019,4.628,0.422,9.285-0.234,13.866c-0.448,3.133-1.47,6.91-1.852,10.052 s0.522,4.886,1.44,9.612C157.287,213.99,160.502,204.633,159.34,195.204z" />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#FF725E",
                }}
                d="M153.795,187.764c3.854,10.728,2.277,23.257-4.115,32.695"
              />
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#263238",
                  }}
                  d="M149.68,220.46c-0.089-0.057,1.006-1.635,2.318-4.417c0.666-1.386,1.33-3.095,1.939-5.031 c0.577-1.946,1.079-4.125,1.384-6.448c0.28-2.327,0.333-4.563,0.257-6.591c-0.111-2.027-0.331-3.847-0.632-5.355 c-0.582-3.021-1.251-4.82-1.151-4.854c0.034-0.012,0.214,0.431,0.511,1.246c0.155,0.406,0.329,0.907,0.484,1.504 c0.161,0.595,0.376,1.266,0.517,2.03c0.351,1.514,0.612,3.352,0.75,5.404c0.104,2.053,0.065,4.322-0.219,6.681 c-0.309,2.355-0.833,4.562-1.443,6.526c-0.643,1.953-1.351,3.669-2.066,5.049c-0.326,0.706-0.701,1.302-1.004,1.838 c-0.298,0.54-0.591,0.983-0.842,1.337C149.993,220.095,149.709,220.48,149.68,220.46z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M104.12,236.023c-0.045,0.05-0.798-0.522-1.924-1.554c-1.126-1.031-2.611-2.538-4.106-4.328 c-1.494-1.792-2.711-3.523-3.524-4.815c-0.814-1.292-1.242-2.136-1.185-2.171c0.065-0.04,0.603,0.733,1.49,1.965 c0.886,1.232,2.138,2.911,3.619,4.688c1.482,1.774,2.91,3.306,3.964,4.398C103.506,235.298,104.171,235.966,104.12,236.023z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M97.536,237.054c-0.062,0.029-0.469-0.682-1.015-1.886c-0.547-1.203-1.217-2.906-1.807-4.837 c-0.588-1.932-0.981-3.72-1.198-5.023c-0.218-1.304-0.277-2.121-0.209-2.132c0.154-0.025,0.732,3.178,1.906,7.003 C96.37,234.008,97.678,236.989,97.536,237.054z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M129.698,218.02c-0.053,0.073-0.605-0.317-1.593-0.708c-0.983-0.396-2.436-0.722-4.05-0.612 c-1.613,0.119-3.006,0.648-3.923,1.178c-0.923,0.527-1.414,0.991-1.477,0.926c-0.04-0.034,0.366-0.602,1.283-1.239 c0.908-0.637,2.372-1.26,4.081-1.385c1.709-0.118,3.247,0.292,4.235,0.795C129.251,217.475,129.733,217.981,129.698,218.02z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#FFBE9D",
              }}
              d="M74.176,215.633c0,0-7.342,7.993-6.784,13.574c0.558,5.581,3.163,17.861,10.047,17.488 c6.884-0.372,13.108-11.436,14.038-20.22c0.93-8.784,0-29.432,0-29.432l-9.804,2.208L74.176,215.633z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#263238",
                  }}
                  d="M59.579,164.425c5.08,6.491,17.674,22.141,17.674,22.141l11.849-3.006 c0.862-0.219,1.502-0.943,1.613-1.826v0c0.072-0.575-0.09-1.154-0.449-1.609l-16.603-21.019 c-1.275-1.614-3.448-2.22-5.374-1.497l-7.594,2.848C59.079,161.064,58.515,163.065,59.579,164.425z"
                />
              </g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#455A64",
                    }}
                    d="M89.457,180.909c-0.026,0.024-0.432-0.366-1.141-1.094c-0.704-0.733-1.714-1.803-2.923-3.16 c-2.425-2.708-5.661-6.555-8.989-10.995c-0.832-1.11-1.632-2.204-2.392-3.268c-0.752-1.056-1.489-2.092-2.483-2.64 c-0.993-0.577-2.128-0.336-3.114,0.011c-1.003,0.341-1.942,0.661-2.81,0.957c-1.657,0.549-3.031,1.005-4.083,1.353 c-0.968,0.309-1.509,0.463-1.521,0.429c-0.012-0.034,0.506-0.253,1.453-0.62c1.038-0.389,2.394-0.897,4.028-1.509 c0.862-0.314,1.796-0.655,2.792-1.018c0.508-0.177,1.055-0.353,1.659-0.404c0.603-0.056,1.257,0.076,1.822,0.379 c1.142,0.62,1.911,1.719,2.67,2.765c0.774,1.052,1.574,2.139,2.394,3.254c3.319,4.428,6.501,8.301,8.85,11.066 c1.134,1.325,2.075,2.424,2.795,3.265C89.128,180.448,89.483,180.884,89.457,180.909z"
                  />
                </g>
              </g>
              <g>
                <path
                  style={{
                    fill: "#FFFFFF",
                  }}
                  d="M66.305,162.25c0.468-0.23,1.102-0.013,1.332,0.454c0.23,0.468,0.013,1.102-0.454,1.332 c-0.468,0.23-1.102,0.013-1.332-0.454C65.621,163.114,65.838,162.48,66.305,162.25"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#FFBE9D",
                  }}
                  d="M76.47,176.438l-5.476,0.913l-2.018,1.794c-0.563,0.501-0.86,1.247-0.757,1.994 c0.098,0.716,0.414,1.65,1.244,2.657c1.307,1.585,2.382,2.383,2.382,2.383s-1.929,3.12,1.058,4.696 c0,0-0.198,3.236,2.929,3.568c0,0,0.912,3.07,3.264,3.236l2.435,2.406l-0.745,1.479l10.871,0.334c0,0,0.081-5.959-0.432-8.68 c-0.492-2.608-0.85-3.444-0.85-3.444c0.553-3.068-1.736-4.341-2.08-4.129c0,0,5.189-1.358,4.524-4.93l-4.981,1.282 l-9.13,1.904l5.848-3.922c0.294-0.267,0.556-0.568,0.769-0.904c0.499-0.785,1.236-2.219,0.605-3.052 c-0.27-0.356-7.467,2.655-7.467,2.655l-4.424,4.129l-1.395-1.982C72.643,180.826,77.189,179.866,76.47,176.438z"
                />
              </g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#EB996E",
                    }}
                    d="M88.993,185.703c0.016,0.152-1.931,0.431-4.242,1.108c-2.316,0.664-4.109,1.469-4.177,1.332 c-0.066-0.117,1.661-1.151,4.032-1.832C86.973,185.619,88.986,185.569,88.993,185.703z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#EB996E",
                    }}
                    d="M86.488,195.113c-0.057-0.072,0.852-0.61,1.816-1.938l0.181-0.249l0.132,0.289 c0.157,0.346,0.471,0.672,0.889,0.849c0.406,0.175,0.957,0.202,1.312-0.08c0.172-0.143,0.226-0.298,0.186-0.568 c-0.049-0.295-0.099-0.594-0.15-0.899c-0.118-0.61-0.17-1.287-0.373-1.845c-0.198-0.563-0.658-0.87-1.218-0.873 c-0.56-0.023-1.111,0.166-1.66,0.307c-1.06,0.261-2.044,0.503-2.935,0.722c-1.673,0.395-2.711,0.604-2.725,0.533 c-0.014-0.071,0.997-0.406,2.636-0.89c0.875-0.25,1.842-0.526,2.883-0.823c0.539-0.148,1.133-0.375,1.817-0.358 c0.337,0.008,0.713,0.088,1.027,0.31c0.319,0.216,0.532,0.55,0.661,0.885c0.247,0.698,0.276,1.334,0.391,1.951 c0.047,0.312,0.094,0.618,0.139,0.92c0.072,0.323-0.076,0.808-0.384,1.004c-0.59,0.424-1.286,0.322-1.783,0.094 c-0.511-0.241-0.877-0.658-1.049-1.101l0.313,0.04c-0.536,0.668-1.073,1.108-1.459,1.376 C86.745,195.038,86.505,195.141,86.488,195.113z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#EB996E",
                    }}
                    d="M76.882,180.027c0.106,0.104-1.072,1.459-2.44,3.19c-1.373,1.728-2.423,3.184-2.548,3.105 c-0.118-0.073,0.747-1.675,2.14-3.428C75.422,181.138,76.784,179.929,76.882,180.027z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#EB996E",
                    }}
                    d="M79.138,183.774c0.045,0.144-1.139,0.501-1.964,1.676c-0.858,1.152-0.826,2.388-0.977,2.39 c-0.063,0.007-0.172-0.3-0.143-0.818c0.025-0.514,0.232-1.229,0.698-1.877c0.469-0.646,1.083-1.065,1.563-1.251 C78.799,183.705,79.125,183.712,79.138,183.774z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#263238",
                    }}
                    d="M137.802,103.21c-0.838-2.2-0.509-4.655-0.838-6.985c-0.726-5.133-4.856-9.253-9.636-11.26 c-4.446-1.867-9.88-1.468-15.242,0.258c-3.675,1.183-7.135,3.249-9.588,6.231c-3.255,3.957-4.408,9.55-2.981,14.471 l26.261-3.791c2.456,2.037,4.97,4.108,7.95,5.246c0.874,0.334,1.816,0.586,2.74,0.44c0.925-0.146,1.824-0.773,2.044-1.682 C138.753,105.144,138.166,104.166,137.802,103.21z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#263238",
                    }}
                    d="M121.48,83.644c5.749-0.97,10.799,1.25,15.642,4.495c4.843,3.246,8.487,8.14,10.588,13.579 c2.213,5.728,2.766,14.013,1.053,19.909c-1.713,5.897-5.878,10.204-11.26,12.105c-7.907,2.792-17.042,2.452-23.724-2.614 c-6.683-5.066-10.496-13.433-10.806-21.813c-0.214-5.773,1.173-11.678,4.405-16.466 C110.61,88.051,115.763,84.476,121.48,83.644"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#FFBE9D",
                  }}
                  d="M112.563,150.85"
                />
              </g>
              <g>
                <path
                  style={{
                    fill: "#FFBE9D",
                  }}
                  d="M112.35,146.167l0.213,4.682l10.314,18.253l15.036-19.205l0.509-47.954 c-0.04-2.222-1.773-4.045-3.99-4.195l-22.917-4.885c-7.299-0.496-11.797,8.638-11.983,15.952 c-0.206,8.126-0.137,18.054,1.05,24.066C102.971,144.97,112.35,146.167,112.35,146.167"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <path
                                                        style={{
                                                          fill: "#263238",
                                                        }}
                                                        d="M102.818,118.893c0.002,0.825,0.702,1.504,1.563,1.517 c0.86,0.014,1.557-0.642,1.555-1.467c-0.002-0.825-0.702-1.504-1.562-1.518 C103.514,117.412,102.816,118.069,102.818,118.893z"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#263238",
                                                          }}
                                                          d="M101.878,113.501c0.201,0.196,1.35-0.723,3.029-0.781 c1.676-0.083,2.94,0.735,3.114,0.522c0.086-0.094-0.125-0.454-0.676-0.824 c-0.543-0.369-1.458-0.713-2.503-0.67c-1.045,0.045-1.91,0.463-2.401,0.876 C101.94,113.039,101.779,113.413,101.878,113.501z"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <path
                                                        style={{
                                                          fill: "#263238",
                                                        }}
                                                        d="M118.052,118.604c0.001,0.825,0.702,1.503,1.563,1.517 c0.86,0.014,1.557-0.642,1.555-1.466c-0.002-0.825-0.702-1.505-1.562-1.518 C118.747,117.122,118.05,117.78,118.052,118.604z"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#263238",
                                                          }}
                                                          d="M117.816,113.112c0.202,0.196,1.352-0.723,3.029-0.781 c1.675-0.083,2.94,0.735,3.115,0.522c0.085-0.094-0.123-0.454-0.676-0.824 c-0.543-0.369-1.457-0.714-2.503-0.67c-1.045,0.045-1.91,0.463-2.401,0.876 C117.88,112.65,117.718,113.024,117.816,113.112z"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <path
                                                        style={{
                                                          fill: "#263238",
                                                        }}
                                                        d="M112.764,125.871c0.004-0.095-1.046-0.237-2.744-0.389 c-0.43-0.028-0.839-0.098-0.922-0.388c-0.113-0.306,0.049-0.775,0.238-1.281 c0.364-1.052,0.745-2.156,1.145-3.311c1.585-4.713,2.716-8.582,2.526-8.644 c-0.19-0.062-1.63,3.707-3.216,8.42c-0.38,1.162-0.745,2.269-1.091,3.329 c-0.144,0.493-0.402,1.056-0.159,1.691c0.128,0.316,0.451,0.536,0.728,0.6 c0.278,0.074,0.519,0.069,0.732,0.074C111.705,125.998,112.761,125.966,112.764,125.871z"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <path
                                                      style={{
                                                        fill: "#EB996E",
                                                      }}
                                                      d="M112.35,146.167c0,0,8.202-0.171,16.125-5.348c0,0-3.779,8.573-15.995,7.892 L112.35,146.167z"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <path
                                                      style={{
                                                        fill: "#EB996E",
                                                      }}
                                                      d="M114.052,129.478c0.715-0.736,1.392-0.915,2.446-0.841 c0.731,0.051,1.089,0.416,1.553,0.993c0.465,0.576,0.528,1.74,0.147,2.32 c-0.429,0.654-1.37,0.837-2.179,0.639c-0.81-0.198-1.376-0.441-2.05-0.942 c-0.187-0.139-0.32-0.453-0.381-0.675C113.4,130.292,113.919,129.648,114.052,129.478"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <path
                                                style={{
                                                  fill: "#263238",
                                                }}
                                                d="M117.916,109.181c0.182,0.45,1.85,0.171,3.828,0.334 c1.982,0.124,3.591,0.64,3.838,0.221c0.109-0.2-0.184-0.619-0.843-1.037c-0.653-0.417-1.684-0.798-2.871-0.886 c-1.187-0.085-2.262,0.143-2.969,0.462C118.187,108.595,117.837,108.967,117.916,109.181z"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <path
                                                style={{
                                                  fill: "#263238",
                                                }}
                                                d="M102.089,106.143c0.309,0.37,1.451-0.069,2.841-0.14 c1.387-0.119,2.577,0.16,2.833-0.249c0.112-0.198-0.088-0.573-0.616-0.915c-0.521-0.34-1.38-0.603-2.334-0.538 c-0.954,0.066-1.769,0.443-2.238,0.851C102.098,105.563,101.952,105.961,102.089,106.143z"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        style={{
                          fill: "#263238",
                        }}
                        d="M142.008,99.675c-2.368-6.983-12.732-16.508-20.077-15.857l-11.613,7.263 c0.785,3.989,3.941,7.416,7.851,8.527c2.088,0.593,4.592,0.721,5.86,2.483c1.295,1.8,0.608,4.469,1.816,6.328 c1.675,2.576,6.119,2.37,7.392,5.166c0.556,1.222,0.305,2.699,0.883,3.911c0.77,1.615,4.93,3.602,6.625,3.03 c1.695-0.572,1.646-3.475,2.174-5.007c0.904-2.617,0.486-3.957,0.146-5.403 C142.259,106.693,143.138,103.007,142.008,99.675z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{
                                fill: "#FFBE9D",
                              }}
                              d="M135.742,122.057c-0.032-0.906,0.935-3.08,1.824-3.254 c2.375-0.462,6.687-0.432,6.955,5.633c0.366,8.29-8.033,6.948-8.058,6.711 C136.444,130.962,135.852,125.185,135.742,122.057z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{
                                  fill: "#EB996E",
                                }}
                                d="M138.857,127.895c0.035-0.028,0.149,0.095,0.398,0.196 c0.243,0.098,0.656,0.156,1.081-0.025c0.862-0.36,1.538-1.783,1.512-3.279c-0.012-0.756-0.202-1.467-0.495-2.05 c-0.28-0.595-0.691-1.002-1.135-1.06c-0.443-0.082-0.744,0.195-0.835,0.433c-0.101,0.237-0.041,0.4-0.084,0.416 c-0.022,0.022-0.184-0.131-0.132-0.476c0.027-0.166,0.115-0.366,0.303-0.534c0.192-0.17,0.48-0.266,0.79-0.246 c0.641,0.016,1.243,0.579,1.565,1.219c0.358,0.641,0.589,1.441,0.603,2.289c0.021,1.673-0.765,3.274-1.949,3.673 c-0.576,0.178-1.071,0.023-1.331-0.161C138.881,128.094,138.829,127.911,138.857,127.895z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      style={{
                        fill: "#FF725E",
                      }}
                      d="M149.524,110.719c0.059,0.009,0.282-0.931,0.313-2.488c0.035-1.552-0.193-3.722-0.947-5.984 c-0.762-2.26-1.89-4.129-2.853-5.345c-0.964-1.223-1.708-1.839-1.75-1.796c-0.153,0.107,2.625,2.865,4.083,7.315 C149.89,106.85,149.337,110.726,149.524,110.719z"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#263238",
                    }}
                    d="M112.729,88.577c-0.767,3.374-5.903,7.696-8.158,10.321 c-4.303,5.009-5.114,5.746-5.079,20.852c-2.024-3.873-3.617-10.64-3.456-15.007c0.161-4.367,1.776-8.741,4.816-11.88 C103.893,89.724,108.405,87.946,112.729,88.577"
                  />
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#455A64",
                  }}
                  d="M146.235,99.572c0.007,0.033-0.494,0.156-1.409,0.348c-0.457,0.103-1.018,0.212-1.678,0.291 c-0.659,0.085-1.407,0.215-2.243,0.257c-1.667,0.156-3.666,0.181-5.878,0.054c-2.209-0.162-4.629-0.497-7.118-1.093 c-2.484-0.62-4.786-1.441-6.821-2.317c-2.021-0.908-3.783-1.853-5.19-2.762c-0.722-0.424-1.326-0.884-1.871-1.263 c-0.549-0.375-0.997-0.731-1.354-1.033c-0.723-0.593-1.111-0.933-1.089-0.959c0.022-0.026,0.451,0.263,1.206,0.806 c0.374,0.277,0.835,0.609,1.395,0.959c0.556,0.355,1.168,0.792,1.896,1.193c1.418,0.863,3.179,1.766,5.188,2.641 c2.023,0.844,4.302,1.641,6.757,2.255c2.46,0.589,4.85,0.933,7.033,1.119c2.186,0.15,4.165,0.163,5.822,0.052 c0.831-0.02,1.575-0.124,2.233-0.183c0.659-0.052,1.221-0.133,1.681-0.206C145.714,99.597,146.228,99.538,146.235,99.572z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  style={{
                    fill: "#455A64",
                  }}
                  d="M147.092,105.518c0.005,0.034-0.336,0.115-0.962,0.234c-0.624,0.127-1.538,0.247-2.674,0.337 c-2.268,0.186-5.447,0.131-8.869-0.539c-3.422-0.68-6.383-1.836-8.411-2.869c-1.016-0.514-1.817-0.972-2.346-1.326 c-0.533-0.347-0.818-0.553-0.801-0.581c0.045-0.074,1.246,0.653,3.297,1.593c2.047,0.942,4.982,2.029,8.356,2.699 c3.375,0.66,6.502,0.77,8.754,0.674C145.691,105.649,147.079,105.432,147.092,105.518z"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    style={{
                      fill: "#455A64",
                    }}
                    d="M149.187,105.725c0.028-0.006,0.15,0.435,0.279,1.248c0.138,0.812,0.26,1.998,0.34,3.467 c0.076,1.469,0.099,3.223-0.002,5.168c-0.115,1.942-0.299,4.086-0.851,6.285c-1.164,4.436-3.986,7.74-6.438,9.385 c-1.224,0.841-2.316,1.344-3.077,1.656c-0.763,0.312-1.202,0.435-1.212,0.408c-0.015-0.038,0.4-0.224,1.131-0.588 c0.73-0.363,1.782-0.909,2.951-1.768c1.176-0.846,2.427-2.058,3.526-3.625c1.06-1.59,2.144-3.435,2.628-5.592 c0.54-2.145,0.738-4.268,0.877-6.189c0.125-1.925,0.14-3.667,0.11-5.127c-0.034-1.46-0.101-2.642-0.178-3.456 C149.202,106.182,149.146,105.732,149.187,105.725z"
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#263238",
                }}
                d="M116.121,132.582c0.01-0.084,0.588,0.122,1.291-0.315c0.339-0.212,0.66-0.588,0.739-1.099 c0.088-0.501-0.052-1.113-0.439-1.556c-0.393-0.446-0.972-0.674-1.497-0.711c-0.529-0.043-1.01,0.108-1.377,0.299 c-0.74,0.395-1.051,0.874-1.119,0.821c-0.051-0.019,0.148-0.599,0.932-1.138c0.387-0.257,0.942-0.483,1.588-0.461 c0.634,0.017,1.355,0.272,1.864,0.847c0.505,0.581,0.659,1.352,0.519,1.992c-0.131,0.657-0.599,1.126-1.03,1.328 C116.677,133.018,116.067,132.619,116.121,132.582z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              style={{
                fill: "#FFBE9D",
              }}
              d="M167.266,200.857l4.459-9.51c0.74-2.968,3.76-3.973,7.462-4.235l3.914-1.6 c0,0,2.854-4.109,5.494-4.054c0.424,0.009,0.796,0.291,0.919,0.697l0,0c0.084,0.276,0.041,0.574-0.116,0.815l-3.212,4.937 l-2.553,1.266c0,0,2.394,0.578,3.343-0.318c0.949-0.896,4.168-3.427,4.168-3.427s2.445-4.414,5.63-4.084 c0.409,0.042,0.649,0.485,0.46,0.851l-2.441,4.71c0,0,4.448-6.685,8.687-4.19l-4.7,5.114c0,0,6.82-3.867,9.543-0.631 l-17.267,7.131l-9.947,1.952l-3.481,9.433l-3.061-1.744L167.266,200.857z"
            />
          </g>
          <g>
            <path
              style={{
                fill: "#FFBE9D",
              }}
              d="M177.607,213.211c0,0,0.528,16.902-2.592,21.563c-3.12,4.661-8.915,10.974-16.094,5.918 l-4.493-17.011c0,0,7.311-8.021,8.639-11.302c3.315-8.188,8.659-21.033,8.659-21.033l9.527,4.242L177.607,213.211z"
            />
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#EB996E",
                }}
                d="M164.147,210.844c0.064,0.041-0.38,0.84-1.078,2.135c-0.7,1.295-1.638,3.096-2.572,5.134 c-0.932,2.04-1.681,3.927-2.204,5.303c-0.523,1.375-0.837,2.233-0.91,2.211c-0.064-0.019,0.125-0.916,0.562-2.332 c0.436-1.415,1.138-3.342,2.078-5.4c0.942-2.056,1.941-3.847,2.727-5.102C163.535,211.538,164.09,210.808,164.147,210.844z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#EB996E",
                }}
                d="M186.189,187.907c0.061,0.13-0.98,0.746-2.325,1.374c-1.346,0.629-2.485,1.033-2.546,0.903 c-0.061-0.13,0.98-0.745,2.326-1.374C184.988,188.181,186.128,187.777,186.189,187.907z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                style={{
                  fill: "#EB996E",
                }}
                d="M187.624,192.376c-0.034-0.064,0.442-0.367,1.261-0.715c0.483-0.198,0.959-0.393,1.438-0.589 c0.276-0.117,0.552-0.234,0.833-0.353c0.317-0.127,0.469-0.247,0.703-0.51c0.867-0.984,1.583-1.994,2.151-2.671 c0.552-0.685,0.972-1.094,1.046-1c-0.008,0.038-0.052,0.114-0.188,0.337c-0.191,0.3-0.377,0.592-0.561,0.881 c-0.496,0.736-1.147,1.762-2.066,2.807c-0.12,0.131-0.237,0.254-0.403,0.377c-0.192,0.141-0.319,0.169-0.477,0.245 c-0.3,0.128-0.591,0.236-0.869,0.334c-0.556,0.195-1.058,0.353-1.479,0.485C188.168,192.264,187.657,192.439,187.624,192.376z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default OfflineLogo;
