import * as React from "react";
const StraightLine = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={2}
    height={191}
    viewBox="0 0 2 191"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.499995 191L0.500004 0L1.5 4.37114e-08L1.5 191L0.499995 191Z"
      fill="#ABB2BF"
    />
  </svg>
);
export default StraightLine;
